<template>
  <div class="cell-box">
    <div style="margin-bottom: 1.2rem">{{ title }}</div>
    <van-collapse
      accordion
      v-model="list1"
      v-for="(item, index) in list"
      :key="index"
    >
      <van-collapse-item
        v-if="index <= list.length"
        :title="item.title"
        :name="index"
        ref="collapse"
      >
        <div class="content_box" v-html="item.content"></div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import { Collapse, CollapseItem } from "vant";
import { problemList } from "../api/guide";
import { wxPublicAuth } from "../api/home";

export default {
  name: "problemList",
  components: {
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
  },
  data() {
    return {
      list: [],
      title: "",
      activeNames: "1",
      flag: true,
      active: "",
      list1: [],
    };
  },
  created() {
    this.title = this.$route.query.title;
    var that = this;
    var timer = setTimeout(function () {
      console.log(that.list);
    }, 5000);
    problemList({
      pid: this.$route.query.pid,
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        var list = res.data.list;
        for (var i = 0; i < list.length; i++) {
          console.log(
            list[i].content.replace(
              /<img/g,
              "<img style='max-width:100%;height:auto;'"
            )
          );
          list[i].content = list[i].content.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;'"
          );
        }
        console.log("list", list);
        this.list = list;
        this.list1 = res.data.list;
      } else {
      }
    });

  },
  methods: {
    wxPublicAuth() {
      wxPublicAuth({
        page: "home",
      })
        .then((res) => {
          console.log(res);

          window.location.href = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>
<style>
.van-cell__title {
  display: flex;
}
.content_box {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid #bbb;
}
.cell-box {
  padding: 0 1.8rem;
}
</style>


